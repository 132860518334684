<template>
  <div class="font-['Prompt'] container mx-0 sm:mx-auto sm:max-w-sm md:border">
  <!-- <div class="font-['Prompt'] container mx-0 sm:mx-auto sm:max-w-sm md:border"> -->
    <slot></slot>
    <LoadingGlobal />
  </div>
</template>

<style scoped>

</style>